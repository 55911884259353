<template>
<div>
  <v-toolbar dense :color=color class="toolBar white--text px-2">

    
    <v-btn icon @click="goToLink(leftMenuItem.link)">
      <img  src="@/assets/icons/Back Arrow@3x.svg"  >
    </v-btn>

    <v-spacer></v-spacer>

    <GoogleTranslate v-if="message.show" @languageChanged="languageChanged"></GoogleTranslate>

    <v-btn icon class="mr-2" @click="showGoogleTranslate" >
      <img  src="@/assets/icons/global@3x.svg" width="40px" height="40px" >
    </v-btn>

    <template  v-for="( item,index) in rightMenuItems">
      <v-btn  icon :key="index" @click="simpleShare" >
        <v-icon color="blue-grey" v-if="item.icon" >{{item.icon}}</v-icon>
        <img v-bind:src="require('@/assets/icons/' + item.customIcon)"  v-if="item.customIcon" >
      </v-btn>
    </template>




  </v-toolbar>


  <!-- TODO: Check and see if this is being used -->
  <!-- <v-dialog
    v-if="showSignin"
  >
    <component-signin></component-signin>
  </v-dialog> -->

  <!-- not being used but left if we want to go back to this -->
  <!-- <ehc-drawer v-model=showShare right>
    <ehc-share @closeShare="closeShare"></ehc-share>
  </ehc-drawer> -->

  <!-- <ehc-dialog v-model="showLink" title="Share this link" max-width = "300px" close>
     <span class="font-weight-bold text-center">{{linkToShare}}</span>
     <p class="text-caption text-center">...has been saved to your clipboard. Paste it into a text or email.</p>
  </ehc-dialog>   -->

</div>
</template>

<script>
import GoogleTranslate from './GoogleTranslate.vue';
import componentSignin from './admin/component-signin.vue'
import EhcShare from './ehc-share.vue'
import mixins from "@/mixins"
export default {
  components: { componentSignin, EhcShare, GoogleTranslate, },
  mixins: [mixins],
  props: {
    'color': {
      type: String,
      default: 'transparent'
      }
    },
  data() {
    return {
      showShare: false,
      showLink: false,
      message: {
        show: false,
      },
      leftMenuItem: 
        {
          icon: "mdi-undo",
          link: "back"
        },
      rightMenuItems: [
        {
          customIcon: "Share@3x.svg",
          link: "share"
        },
      ],
    }
  },
  methods: {
    closeShare: function(){
      this.showShare = false
    },
    simpleShare() {
      const imageUrl = this.avatarImg;
      const guestBookUrl = `${this.$store.getters.baseUrl}${this.$store.getters.propertyId}`;
          if (navigator.share) {
            navigator.share({ 
              url: guestBookUrl,
              title: this.property.name,
              text: `Check out this welcome book for ${this.property.name}!`
              })
              .then(() => {
                console.log('Image URL shared successfully');
              })
              .catch(error => {
                console.error('Error sharing image URL:', error);
              })
          } else {
            console.log('Web Share API is not supported');
            this.shareLink()
          }
    },

    goToLink: function(link){
      // Uncomment to deactivate back button on guest info form
      // if ( this.$router.currentRoute.name === "GuestInfo" ) { return false }
      if ( link === "share") { this.showShare = !this.showShare }
      else if ( this.$store.getters.popup ) { this.$store.commit('setPopup',false) }
      else if ( this.$store.getters.selCat ) { this.$store.commit('setSelCat',null) }
      else if ( link === "back" && this.$route.name !== 'Home' ) { this.$router.replace({name:"Home"}) }
      else if ( link === 'logout' ) { this.$store.dispatch("logout"); return }
      else if ( link !== "back" ) { this.$router.replace( {name: link} ) }
    },

    shareLink: function(){
      navigator.clipboard.writeText(this.linkToShare)
      this.showLink = !this.showLink
    },
    languageChanged: function(){
      console.log('languageChanged')
      this.message.show = false
      // document.body.style.top = '0';
    },
    toggleGoogleTranslate: function(){
      console.log('toggleGoogleTranslate')
      this.showGoogleTranslate = !this.showGoogleTranslate
    },
    openThirdPartyDropdown() {
      console.log('openThirdPartyDropdown');
      // Get a reference to the third-party select element by class
      const selectElement = document.querySelector('.goog-te-combo');
      console.log('selectElement', selectElement);

      if (selectElement) {
        console.log("Open the select dropdown");
        selectElement.click();
      }
    },
    showGoogleTranslate(){
        this.message = {
          title: "Select a language",
          text: "Select the page you want to add.",
          icon: "mdi-pencil",
          color: "info",
          show: true,
          slotName: "googleTranslate"
        }
    }, 


  },
  computed: {
    showSignin: function(){
      return this.$store.getters.showSignin
    },
    property: function(){
      return this.$store.getters.property
    },
    linkToShare: function() {
      return `${this.$store.getters.baseUrl}${this.$store.getters.propertyId}`
    }

  }

}
</script>

<style>
.toolBar {
  margin-bottom: 20px;
  margin-top: 15px;
  box-shadow:none!important;
}

.toolBar .v-btn{background-color: rgba(255,255,255,0.15);}

.white-button {
  background-color: white !important;
}

body {
  top: 0 !important;
}

.skiptranslate {
  display: none !important;
}

.skiptranslate.goog-te-gadget {
  display: block !important;
}


</style>
