<template>
  <div>
    
    <v-card class="rounded-xl pt-2 component-weather" color="weather" >
 
      <v-card-text class="pa-1 px-2 white--text">
      <v-container>

        <v-row><v-col class="font-weight-medium d-flex pl-1 pb-0 pt-2 weatherCityName "><strong>{{city}}</strong></v-col></v-row>
        <v-row>
          <v-col v-for="(title, index) in forecastArrayMapped" :key="index" :cols="colNum" class="pb-0 mb-0 px-1 pt-4">
            <template v-if="index === 0">
              <span class="text-h7 pb-0 mb-0" dark>TODAY</span>
            </template>
            <p v-else class="text-h7 pb-0 mb-0">{{title.day}}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="(day, index) in forecastArrayMapped" :key="index" :cols="colNum" class="mt-0 pt-0 px-1">            
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"> 
                <span v-bind="attrs"
                v-on="on"
                >
                  <v-img
                    width="50px"
                    :src="require(`@/assets/weatherIcons/${day.icon}.svg`)"
                    class="mx-auto"
                  ></v-img>
                  <p class="text-h6 font-weight-black mb-1">{{day.tempMax}} &deg;{{unitsSymbol}}</p>
                  <p class="text-h8 font-weight-medium">{{day.tempMin}} &deg;{{unitsSymbol}}</p>
                  </span>
              </template>
              <span>{{day.description}}</span>
            </v-tooltip>
          </v-col>
        </v-row>

      </v-container>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>

export default {
  data () {
    return {
      daysToShow: 4,
      city: null,
    }
  },
  methods: {

    //set Forcast from a weatherOnj either created from the mapAddree field in property or from Geocode from city and state
    setWeatherForecast(property){
      if ( property.mapAddress ) {
        this.getWeatherObjFromPropertyMapAddress(property)
      } else {
        this.getWeatherObjFromGeocode(property)
      }
    },
    getWeatherObjFromPropertyMapAddress(property){
      let mapAddress = this.property.mapAddress
      this.city = mapAddress.city || mapAddress.state
      mapAddress.units = property.unit_of_temperature || "imperial"
      this.setForcastArray(mapAddress)
    },
    getWeatherObjFromGeocode(property){
      let weatherObj = {}
      this.city = property.city
      let obj = { address: `${property.city}, ${property.state}` }
      console.log('getWeatherObjFromGeocode: ', obj)
      let service = new window.google.maps.Geocoder
      service.geocode(obj, function(res){
        let location = res[0].geometry.location
        let lat = location.lat()
        let lng = location.lng()
        weatherObj = {
          lat: lat,
          lng: lng,
          city: property.city,
          units: property.unit_of_temperature || "imperial"
        }
      }).then( () => this.setForcastArray(weatherObj) )
    },
    setForcastArray(weatherObj){
      this.$store.dispatch("getForecastArray",weatherObj)
    },

    formatForcastDay(dtNumber){
      let day = new Date(1000*dtNumber)
      let options = { weekday: 'short'}
      let locale = "en-US"
      return day.toLocaleString(locale,options)
    },
  },

  computed: {
    property: function(){
      return this.$store.getters.property
    },

    //get forcast from store using this.weatherObj
    forecastArray: function(){
      return this.$store.getters.forecastArray.slice(0,this.daysToShow)
    },
    forecastArrayMapped: function(){
      return this.forecastArray.map( (el) => {
        let obj = {}
        obj.day = this.formatForcastDay(el.dt)
        obj.temp = Math.floor(el.temp.day) 
        obj.tempMax = Math.floor(el.temp.max) 
        obj.tempMin = Math.floor(el.temp.min) 
        obj.weather = el.weather[0].main
        obj.description = el.weather[0].description
        obj.icon = el.weather[0].icon
        obj.iconUrl = `@/assets/weatherIcons/${obj.icon}.svg`
        return obj
        })
    },

    //number of columns in displayed table
    colNum: function() {
          return Math.floor(12/this.daysToShow)
        },
    unitsSymbol: function(){
      if ( this.property.unit_of_temperature === "metric" ) { return "C" }
      return "F"
    }

  },

  watch: {
    property: function(){
      this.setWeatherForecast(this.property)
    }
  },

  mounted() {
    this.setWeatherForecast(this.property)
  },
}

</script>

<style >

.weatherCityName{
  font-size: 18px
}

.component-weather {
  margin: 0 auto;
  max-width: 800px;
}


</style>